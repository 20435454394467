import React from "react";
import styles from "./home.scss";

class Home extends React.Component {


    render() {


        const designedNav = (
            <nav className="_links">
                <ul>
                    <li><a href="#">about</a></li>
                    <li><a href="#">cv</a></li>
                    <li><a href="#">portfolio</a></li>
                </ul>
            </nav>
        );

        const designedView = (

            <div className="_designed">
            <div className="_present">


                <div className="_title-wrap">

                    <div className="_title">
                        Hi, I'm <span className="_matthew _highlight"><span className="_text">&nbsp;Matthew.&nbsp;</span><span id="circle" className="_circle"></span></span>
                    </div>

                </div>

                <div className="_more">
                    <div className="_subtitle">
                        I make software.
                    </div>
                </div>



            </div>



            </div>
        );

        const sourceView = (
            <div className="_source">
                <nav className="_links">
                    <ol>
                        <li className="_line">
                            <span>&#123;</span>
                        </li>
                        <li>
                            <ul>
                                <li className="_line">
                                    <span className="_comment">/*Hi, I'm Matthew - I make software.*/</span>
                                </li>
                                <li className="_line">
                                    <span><span className="_keyword">let</span>&nbsp;<span className="_symbol">menu</span> = [</span>
                                </li>
                                <li>
                                    <ul>
                                        <li className="_line">
                                            <a href="#"><span className="_symbol">about</span></a>
                                        </li>
                                        <li className="_line">
                                            <a href="#"><span className="_symbol">cv</span></a>
                                        </li>
                                        <li className="_line">
                                            <a href="#"><span className="_symbol">portfolio</span></a>
                                        </li>
                                    </ul>
                                </li>
                                <li className="_line">
                                    <span>];</span>
                                </li>
                            </ul>
                        </li>
                        <li className="_line">
                            <span>&#125;</span>
                        </li>
                    </ol>
                    <div className=""></div>
                </nav>

            </div>

        );


        const activeView = designedView;
        return (
            <div className="_home">


                <div className="_content-wrap">

                    {activeView}

                </div>


            </div>
        )
    }
}

export default Home;